import createCache from '@emotion/cache';
import { Stage } from './types';

export const QUERY_PARAM_LABELS = {
  LOGIN_HINT: 'login_hint',
  CLIENT_ID: 'client_id',
  REDIRECT_URI: 'redirect_uri',
  STATE: 'state',
  TOKEN_TYPE: 'token_type',
  EXPIRES_IN: 'expires_in',
  ACCESS_TOKEN: 'access_token',
  ID_TOKEN: 'id_token',
  NONCE: 'nonce',
  RESPONSE_TYPE: 'response_type',
  SCOPE: 'scope',
  KEEP_USER_SIGNED_IN: 'keepUserSignedIn',
  RELYING_PARTY: 'relying_party',
  CODE: 'code',
  DISPLAY: 'display',
  PRIMARY_POOL_ID: 'primaryUserPoolId',
};


export const TOKEN_STATUSES = {
  INVALID_SESSION: 'EXPIRED_TOKEN',
  SUCCESS: 'SUCCESS'
}

export const EVENT_KEY_ENTER = 'Enter';

export const USER_POOL_ID = import.meta.env.VITE_USER_POOL_ID as string;
export const USER_POOL_CLIENT_ID = import.meta.env.VITE_USER_POOL_CLIENT_ID as string;
export const USER_POOL_REGION = import.meta.env.VITE_USER_POOL_REGION as string;
export const DISPLAY_PAGE = 'page';

const CLOUDFRONT_NONCE_COOKIE_KEY_NAME = 'Cloudfront-Nonce='

const DYNAMIC_CLIENT_CONFIG_COOKIE_KEY_NAME = 'Client-Config='

export const DYNAMIC_CLIENT_CONFIG = document.cookie.split('; ').filter(row => row.startsWith(DYNAMIC_CLIENT_CONFIG_COOKIE_KEY_NAME)).map(c => c.split('=')[1])[0]

export const EMOTION_CACHE = createCache({
  nonce: document.cookie.split('; ').filter(row => row.startsWith(CLOUDFRONT_NONCE_COOKIE_KEY_NAME)).map(c => c.split('=')[1])[0]
});

export const STAGE = import.meta.env.MODE as Stage;

export const ATOZ_TEMPLATE_LABEL = 'atoz';
export const DEFAULT_TEMPLATE_LABEL = 'default';
export const NO_CUSTOMER_CONFIG_FOUND_ERROR = 'NO CUSTOMER CONFIG FOUND';

export const PASSWORD_PROVIDER = 'Password';
export const FEDERATE_PARAMS = 'FEDERATE_PARAMS';
export const FEDERATION_INITIATED = 'FEDERATION_INITIATED';

export const SPINNER_TIMEOUT = 5; // in seconds


export const CUPID_API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;
export const REGIONAL_CUPID_API_BASE_URL = import.meta.env.VITE_API_REGIONAL_BASE_URL as string;

export const PROMPT_WEBAUTHN_LOCAL_STORAGE_KEY = 'promptRegisterWebauthn';
export const WEBAUTHN_REGISTERED_USERNAME_KEY = 'WEBAUTHN_REGISTERED_USERNAME';
export const DEVICE_WEBAUTHN_PROMPTS_COUNT_KEY = 'DEVICE_WEBAUTHN_PROMPTS_COUNT';
export const LAST_WEBAUTHN_PROMPT_TIMESTAMP_KEY = 'LAST_WEBAUTHN_PROMPT_TIMESTAMP';

export const FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER = 'WebAuthnChallengeAnswerException';
export const LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException';
export const NOT_ALLOWED_ERROR = 'NotAllowedError';
export const INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException'

export const COGNITO_SMS_MFA_CHALLENGE_LABEL = 'SMS_MFA';
export const COGNITO_NEW_PASSWORD_REQUIRED_CHALLENGE_LABEL = 'NEW_PASSWORD_REQUIRED';
export const PASSWORD_ATTEMPTS_EXCEEDED = 'Password attempts exceeded';
export const INCORRECT_USERNAME_OR_PASSWORD = 'Incorrect username or password.';
export const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException';
export const PASSWORD_HIDDEN_ARIA = 'resources:passwordHidden';
export const PASSWORD_UNHIDDEN_ARIA = 'resources:passwordUnhidden';

export const MANAGE_WEBAUTHN = '/webauthn/manage';

export const REQUEST_ID_HEADER = 'x-request-id';