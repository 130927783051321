import {
    Button,
    ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { H3, Label } from '@amzn/stencil-react-components/esm/text';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { getCustomerConfig, getPreferredAuthMethod } from '../../../globals/utils';
import { WebAuthnError, WebAuthnErrors, WebAuthnSignIn } from '../CustomLoginUI/WebAuthnSignin';
import { IdPrismUser } from '../../../globals/types';

/*
    This component uses CUSTOM_AUTH for Cognito to preform email OTP validation. This screen should only be displayed in a DR scenario.
    Email OTP is the first form of validation in the custom auth flow for all MFA methods (SMS, WebAuthN, and Email).
    SMS OTP is second factor validation, if this step succeeds we can authenticate the user.
    In the WebAuthn case, if WebAuthn is the chosen method, then there will be no SMS OTP screen shown.
    If WebAuthn is mandatory, then there will be no EMAIL OR SMS OTP screen shown.
*/
export default function FailoverOTPWebAuthN() {
    // TODO Add retry mechanism for custom auth OTPs
    const { t } = useTranslation();
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const [otpCode, setOTPCode] = useState('')
    const cognitoUser = useLoginStore((state) => state.cognitoUser) as CognitoUser;
    const setIntermediateUser = useLoginStore((state) => state.setIntermediateUser);
    const loginFormState = useLoginStore((state) => state.loginFormState);
    const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
    const username = useLoginStore((state) => state.username);
    const [otpError, setOtpError] = useState<boolean>(false);
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [sendOtp, setSendOtp] = useState<boolean>(false);
    const [showWebAuthn, setShowWebAuthn] = useState<boolean>(false);
    const [webAuthnError, setWebAuthnError] = useState<string | null>(null);

    const [customerConfig] = useState(() => getCustomerConfig());
    const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
    const [preferredAuthMethod, setPreferredAuthMethod] = useState<string | null>();

    useEffect(() => {
        setPreferredAuthMethod(getPreferredAuthMethod(customerConfig));
    }, [customerConfig])

    useEffect(() => {
        setShowWebAuthn(preferredAuthMethod === 'WebAuthN');

        if (customerConfig.webauthnSupport === 'MANDATORY') {
            setShowOtp(false);
            setSendOtp(false);
        } else {
            setShowOtp(true);
            setSendOtp(true);
        }
    }, [preferredAuthMethod])

    useEffect(() => {
        async function initiateOTPSignIn() {
            const user = await Auth.signIn(username)
            setCognitoUser(user);
        }

        if (loginFormState === LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP && sendOtp) {
            initiateOTPSignIn();
        }
    }, [loginFormState, username, sendOtp])

    const onClickSubmitOTP = async () => {
        setShowSpinnerOverlay(true);
        let intermediateUser: IdPrismUser;
        try {
            intermediateUser = await Auth.sendCustomChallengeAnswer(cognitoUser, otpCode, { preferredAuthMethod: "OTP" })
            setIntermediateUser(intermediateUser);
            setShowSpinnerOverlay(false);
        } catch (error) {
            console.error(error);
            setOtpError(true);
            setShowSpinnerOverlay(false);
            return;
        }


        if (loginFormState === LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP) {
            setLoginFormState(LOGIN_FORM_STATES.FAILOVER_SMS_OTP);
        } else {
            if (intermediateUser.challengeName === undefined) {
                setCognitoUser(intermediateUser);
            }
        }
        setOTPCode('');
    };

    function onWebAuthnError(error: WebAuthnError) {
        switch (error) {
            case WebAuthnErrors.BIOMETRICS_NOT_SUPPORTED:
                setWebAuthnError(t('resources:errorCapturingBiometrics'))
                break;
            case WebAuthnErrors.NO_CREDENTIALS_REGISTERED:
                setWebAuthnError(t('resources:noWebauthnRegistered'))
                break;
            case WebAuthnErrors.INCORRECT_WEBAUTHN:
                setWebAuthnError(t('resources:webauthnNotSupported`'))
                break;
            default: 
                setWebAuthnError(t('resources:generalError'))
            break;
        }
    }

    return (
        <>
            <H3 id={`${loginFormState}-header`}> {t('resources:logIn')}</H3>
            <Col gridGap='S200'>
                {showOtp &&
                    <>
                        <Label htmlFor='input-id-4'>{loginFormState === LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP ? t('resources:failoverEmailOTPInstructions') : t('resources:failoverSMSOTPInstructions')}</Label>
                        <Input
                            data-testid={`${loginFormState}-code-input`}
                            id='input-id-4'
                            value={otpCode}
                            type='text'
                            autoCapitalize='none'
                            autoCorrect='off'
                            onChange={(event) => setOTPCode(event.target.value)}
                        />
                    </>
                }
                {otpError && (
                    <InputFooter id='input-footer-1' error>
                        {t('resources:incorrectVerificationCode')}
                    </InputFooter>
                )}
                {showOtp &&
                    <Row
                        style={{ marginTop: 10 }}
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center'
                    > 
                        <Button
                            data-testid={`${loginFormState}-submit-button`}
                            disabled={!otpCode}
                            onClick={onClickSubmitOTP}
                            style={{ width: '100%' }}
                            variant={ButtonVariant.Primary}
                        >
                            {t('resources:verify')}
                        </Button>
                        <Button
                            data-testid={`${loginFormState}-back-button`}
                            onClick={() => setLoginFormState(LOGIN_FORM_STATES.USERNAME_STATE)}
                            style={{ width: '100%' }}
                            variant={ButtonVariant.Primary}
                        >
                            {t('resources:back')}
                        </Button>
                    </Row>
                }
                {showWebAuthn &&
                    <>
                        <Row>
                            <>
                                <WebAuthnSignIn onClick={() => setWebAuthnError(null)} onError={onWebAuthnError} setLoading={setShowSpinnerOverlay} />
                            </>
                        </Row>

                        {webAuthnError &&
                            <InputFooter id='input-footer-2' error>
                                {webAuthnError}
                            </InputFooter>
                        }
                    </>
                }
            </Col>
        </>
    );
}
