import { create } from 'zustand';
import { IdPrismUser } from '../globals/types';

export enum LOGIN_FORM_STATES {
  USERNAME_STATE = 'USERNAME_STATE',
  PASSWORD_STATE = 'PASSWORD_STATE',
  FORGOT_PASSWORD_STATE = 'FORGOT_PASSWORD_STATE',
  NEW_PASSWORD_FIRST_LOGIN_STATE = 'NEW_PASSWORD_FIRST_LOGIN_STATE',
  MFA_STATE = 'MFA_STATE',
  LOGGED_IN_STATE = 'SIGNED_IN_STATE',
  TOO_MANY_FAILED_AUTH = 'TOO_MANY_FAILED_AUTH',
  FAILOVER_EMAIL_OTP = 'FAILOVER_EMAIL_OTP',
  FAILOVER_SMS_OTP = 'FAILOVER_SMS_OTP',
  SPINNER = 'SPINNER',
  BIOMETRICS_STATE = 'BIOMETRICS_STATE',
  WEB_AUTHN_STATE = "WEB_AUTHN_STATE",
  CREDENTIAL_MANAGEMENT = "CREDENTIAL_MANAGEMENT",
  ATOZ_ENABLE_WEBAUTHN_STATE = 'ATOZ_ENABLE_WEBAUTHN_STATE',
  ATOZ_DISABLE_WEBAUTHN_STATE = 'ATOZ_DISABLE_WEBAUTHN_STATE',
  ATOZ_WEBAUTHN_ENABLED_STATE = 'ATOZ_WEBAUTHN_ENABLED_STATE',
  ATOZ_WEBAUTHN_DISABLED_STATE = 'ATOZ_WEBAUTHN_DISABLED_STATE'
}

interface LoginState {
  loginFormState: LOGIN_FORM_STATES;
  firstTimeLogin: boolean;
  promptRegisterWebauthn: boolean;
  webauthnEnabled: boolean;
  showSpinnerOverlay: boolean;
  showSuccessResetPasswordBanner: boolean;
  webauthnRegisteredOnDevice: boolean;
  webauthnPrompted: boolean;

  username: string;
  password: string;
  mfaCode: string;

  errorRedirecting: boolean;

  /*
  Cognito User Object returned by Amplify Sign In Functions.
  This object is sometimes required for subsequent operations including MFA verifications.
  */
  cognitoUser?: IdPrismUser;
  intermediateUser?: IdPrismUser;

  setLoginFormState: (loginFormState: LOGIN_FORM_STATES) => void;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  setMfaCode: (mfaCode: string) => void;
  setFirstTimeLogin: (firstTimgLogin: boolean) => void;
  setPromptRegisterWebauthn: (promptRegisterWebauthn: boolean) => void;
  setWebauthnEnabled: (webauthnEnabled: boolean) => void;
  setShowSpinnerOverlay: (showSpinnerOverlay: boolean) => void;
  setShowSuccessResetPasswordBanner: (showSuccessResetPasswordBanner: boolean) => void;
  setWebauthnRegisteredOnDevice: (webauthnRegisteredOnDevice: boolean) => void;
  setWebauthnPrompted: (webauthnPrompted: boolean) => void;

  setErrorRedirection: (errorRedirecting: boolean) => void;

  setCognitoUser: (cognitoUser: IdPrismUser) => void;
  setIntermediateUser: (intermediateUser: IdPrismUser) => void;

  clearLoginState: () => void;
}

export const useLoginStore = create<LoginState>()((set) => ({
  loginFormState: LOGIN_FORM_STATES.SPINNER,
  firstTimeLogin: false,
  promptRegisterWebauthn: true,
  webauthnEnabled: false,
  showSpinnerOverlay: false,
  showSuccessResetPasswordBanner: false,
  webauthnRegisteredOnDevice: false,
  webauthnPrompted: false,

  username: '',
  password: '',
  mfaCode: '',

  errorRedirecting: false,

  cognitoUser: undefined,

  setLoginFormState: (loginFormState: LOGIN_FORM_STATES) =>
    set(() => ({ loginFormState })),

  setUsername: (username) => set(() => ({ username: username.toLocaleLowerCase() })),
  setPassword: (password) => set(() => ({ password })),
  setMfaCode: (mfaCode) => set(() => ({ mfaCode })),
  setFirstTimeLogin: (firstTimeLogin) => set(() => ({ firstTimeLogin })),
  setPromptRegisterWebauthn: (promptRegisterWebauthn) => set(() => ({ promptRegisterWebauthn })),
  setWebauthnEnabled: (webauthnEnabled) => set(() => ({ webauthnEnabled })),
  setShowSpinnerOverlay: (showSpinnerOverlay) => set(() => ({ showSpinnerOverlay })),
  setShowSuccessResetPasswordBanner: (showSuccessResetPasswordBanner) => set(() => ({ showSuccessResetPasswordBanner })),
  setWebauthnRegisteredOnDevice: (webauthnRegisteredOnDevice) => set(() => ({ webauthnRegisteredOnDevice })),
  setWebauthnPrompted: (webauthnPrompted) => set(() => ({ webauthnPrompted })),

  setErrorRedirection: (errorRedirecting) => set(() => ({ errorRedirecting })),

  setCognitoUser: (cognitoUser) => set(() => ({ cognitoUser })),
  setIntermediateUser: (intermediateUser) => set(() => ({ intermediateUser })),

  clearLoginState: () =>
    set(() => ({
      loginFormState: LOGIN_FORM_STATES.USERNAME_STATE,

      username: '',
      password: '',
      mfaCode: '',

      errorRedirecting: false,

      cognitoUser: undefined,
      intermediateUser: undefined,
    })),
}));
